import React from 'react';

import {
  TitleColour,
  TitleSpacing,
  Bold,
  h1Size,
  LineHeight,
} from '../../utils/variables';

export default function TitleH5(props) {
  const { colour, margin, line, size, style, className, children, ...other } =
    props;
  return (
    <header className={className}>
      <h5
        className="title"
        itemProp="name"
        style={{
          ...style,
          fontWeight: Bold,
          color: colour || TitleColour,
          marginBottom: margin || TitleSpacing,
          lineHeight: line || LineHeight,
          fontSize: size || h1Size,
        }}
        {...other}
      >
        {children}
      </h5>
    </header>
  );
}
